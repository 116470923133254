/*-------------------------
    Variables
-------------------------*/
:root {
  // Color
  --color-primary: #277CB9;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-light: #ecf2f6;
  --color-dark: #27272e;
  --color-text-dark: #292930;
  --color-accent1: #ffdc60;
  --color-accent2: #fab8c4;

  --color-blue-shade: #6865ff;
  --color-link: #8ccae0;
  --color-mabel: #dbf8ff;
  --color-fog: #dbdeff;
  --color-pink-shade: #ffd3db;
  --color-peach: #ffeddc;
  --color-rose: #c75c6f;

  --color-chart1: #896ba7;
  --color-chart2: #badeff;

  --color-body: #525260;
  --color-gray-1: #757589;
  --color-gray-2: #999fae;
  --color-gray-3: #999ab8;
  --color-gray-4: #99a1aa;
  --color-ship-gray: #42424a;
  --color-ghost: #c7c7d5;
  --color-mercury: #e5e5e5;

  // Gradient Color
  --gradient-primary: linear-gradient(
    90deg,
    #ecf2f6 0%,
    rgba(236, 242, 246, 0) 70.31%
  );
  --gradient-blue: linear-gradient(145.92deg, #5956e9 20.18%, #9991ff 76.9%);
  --gradient-accent: linear-gradient(180deg, #fab8c4 0%, #ffedf0 100%);
  --gradient-white: linear-gradient(
    266.3deg,
    rgba(236, 242, 246, 0) 7.84%,
    #ecf2f6 29.1%,
    rgba(236, 242, 246, 0) 64.32%
  );
  --gradient-dark: linear-gradient(180deg, #27272e 0%, #303035 100%);

  // Border
  --border-light: 1px solid #e3e6e9;
  --border-lighter: 1px solid #ecf2f6;
  --border-dark: 1px solid var(--color-ship-gray);
  --border-gray: 1px solid var(--color-gray-4);

  @font-face {
    font-family: 'deltaFontAr';
    src: url(../fonts/Tajawal-Bold.ttf) format('truetype');
    font-style: normal;
    font-weight: lighter;
    font-display: swap;
  }
  @font-face {
    font-family: 'deltaFontEn';
    src: url(../fonts/Comfortaa-Medium.ttf) format('truetype');
    font-style: normal;
    font-weight: lighter;
    font-display: swap;
  }
  //Font Family
  --font-primary: 'deltaFontEn';
  --font-secondary: 'deltaFontEn';
  --font-awesome: 'deltaFontEn';

  // Transition
  --transition: all 0.3s ease-in-out;

  //Fonts Size
  --font-body-1: 18px;
  --font-body-2: 16px;
  --font-body-3: 14px;
  --font-body-4: 12px;

  // Line Height
  --line-height-b1: 1.5;
  --line-height-b3: 1.1;

  // Heading Font
  --h1: 80px;
  --h2: 64px;
  --h3: 48px;
  --h4: 32px;
  --h5: 24px;
  --h6: 20px;
}

// Layouts Variation
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout: 'only screen and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';
