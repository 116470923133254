

/*=======================================================================
1. Default Styles
=========================================================================*/
@import 'default/variable';
@import 'default/reset';
@import 'default/typography';
@import 'default/form';
@import 'default/classes';

/*=======================================================================
2. Elements Styles
=========================================================================*/
@import 'elements/animate';
@import 'elements/back-top';
@import 'elements/breadcrumb';
@import 'elements/button';
@import 'elements/pagination';
@import 'elements/section-heading';
@import 'elements/slick';

/*=======================================================================
3. Template Styles
=========================================================================*/
@import 'template/about';
@import 'template/banner';
@import 'template/brand';
@import 'template/call-to-action';
@import 'template/case-study';
@import 'template/contact';
@import 'template/counterup';
@import 'template/faq';
@import 'template/pricing';
@import 'template/process';
@import 'template/project';
@import 'template/service';
@import 'template/team';
@import 'template/testimonial';
@import 'template/why-choose';
@import 'template/common-pages';
@import 'template/splash.scss';
@import 'template/onepage';

/*=======================================================================
4.Blocks Styles 
=========================================================================*/
@import 'header/header';
@import 'blog/blog';
@import 'blog/widget';
@import 'footer/footer';

/*=======================================================================
5. Dark Style 
=========================================================================*/
@import 'dark-style/dark-version';

/*=======================================================================
6. Spacing 
=========================================================================*/
@import 'default/spacing';

/*=======================================================================
7. RTL 
=========================================================================*/
// @import "rtl/rtl";

@font-face {
  font-family: 'deltaFontAr';
  src: url(../../../src/assets/scss/fonts/Tajawal-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: lighter;
  font-display: swap;
}
@font-face {
  font-family: 'deltaFontEn';
  src: url(../../../src/assets/scss/fonts/Comfortaa-Medium.ttf)
    format('truetype');
  font-style: normal;
  font-weight: lighter;
  font-display: swap;
}

body {
  font-family: 'deltaFontEn';
  cursor: url('../../../public/images/others/cloudImg.webp'), auto;
}
